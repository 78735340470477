html {
	min-height: 100%;
	overflow: hidden;
	overflow-y: initial;
	scroll-behavior: smooth;
	&.flow {
		overflow: hidden !important;
		body {
			overflow: hidden !important;
		}
	}
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 10px;
	@media (max-width: 1440px) {
		font-size: 10px;
	}
	@media (max-width: 1400px) {
		font-size: 9.7222px;
	}
	@media (max-width: 1368px) {
		font-size: 9.5px;
	}
	@media (max-width: 1366px) {
		font-size: 9px;
	}
	@media (max-width: 1300px) {
		font-size: 9.44444px;
	}
	@media (max-width: 1280px) {
		font-size: 8.88889px;
	}
	@media (max-width: 1208px) {
		font-size: 8.38889px;
	}
	@media (max-width: 1152px) {
		font-size: 8px;
	}
	@media (max-width: 1024px) {
		font-size: 7.1111px;
	}
	&::-webkit-scrollbar {
		width: 0;
	}
}

body {
	position: relative;
	font-weight: 400;
	color: #333333;
	margin: 0;
	padding: 0;
	background-color: #f6f6f6;
}

input {
  --tw-ring-shadow: 0 0 #000 !important;
}
