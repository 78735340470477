// AUTH SCREENS

.auth_box {
  min-width: 65rem;
  max-width: 65rem;
  //padding: 7rem 14rem 8rem;

  @media (max-width: 500px) {
    min-width: 50rem;
    margin-left: 1rem;
    margin-right: 1rem;
    //padding: 8rem 4rem 8rem;
  }

  @media (max-width: 321px) {
    min-width: 40rem;
    margin-left: 1rem;
    margin-right: 1rem;
    //padding: 8rem 4rem 8rem;
  }
}

.auth-bg-image {
  max-width: unset;
  width: 100%;
}

//PIN Input Stylings
.pincode-input-container {
  .pincode-input-text {
    padding: 0 !important;
    margin: 0 10px !important;
    text-align: center !important;
    border: 1px solid !important;
    background: transparent !important;
    width: 7rem !important;
    height: 7rem !important;
    border: 1px solid #adadad !important;
    border-radius: 5px !important;
    font-size: 4rem !important;
    font-weight: 600;

    @media (max-width: 500px) {
      margin: 0 2px !important;
    }
  }

  .pincode-input-text:focus {
    outline: none;
    box-shadow: none;
  }
}

.input-form-calendar {
  text-align: center;
  max-width: 15.9rem;
  color: white;
  font-size: 1.4rem;
  height: 4.6rem;
  padding: "2.7rem 0";
  background-color: transparent;
  border: 0.2rem solid white;
  border-radius: 0.7rem;
  margin-top: 1rem;

  &::placeholder {
    text-align: center;
    color: white;
  }
}

.input-call-calendar {
  text-align: center;
  max-width: 15.9rem;
  color: white;
  font-size: 1.4rem;
  height: 3.6rem;
  // padding: "2.7rem 0";
  background-color: transparent;
  border: 0.2rem solid white;
  border-radius: 0.7rem;
  margin-top: 1rem;

  &::placeholder {
    text-align: center;
    color: white;
  }
}

.video_slider::-webkit-slider-thumb {
  width: 1.11rem;
  height: 1.7rem;
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.video_slider::-moz-range-thumb {
  width: 1.11rem;
  height: 1.7rem;
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

//Account Input

.account_input {
  .input_wrapper {
    margin-bottom: 1rem;
  }

  input {
    margin-top: 1rem;
    width: 100%;
    border-radius: 2px;
    border-color: #cacaca;
    color: #333333;
    border-radius: 1rem;
    font-size: 3.2rem;
    height: 7rem;
    background-color: #f0f0f0;
    padding: 0 2rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #cacaca;
      line-height: 3.3rem;
      background-color: #f0f0f0;
      padding-left: 2rem;
      padding-right: 2rem;
      height: 7rem;
    }
  }

  label {
    color: #333333;
    font-size: 2.8rem;
    line-height: 3.6rem;
    margin-top: 2.5rem;
    margin-bottom: 1.2rem;
  }
}

// //Register Event Input

.register_event_input {
  // @apply flex flex-col pb-[4.5rem];
  display: flex;
  flex-direction: column;

  label {
    color: #fff;
    font-size: 2.6rem;
    font-weight: 400;
    padding-bottom: 1.5rem;
  }

  input {
    color: #fff;
    font-size: 2.6rem;
    height: 7rem;
    width: 100%;
    background-color: #202f39;
    border: 2px solid #888888;
    border-radius: 1rem;
    text-align: center;

    &:focus {
      outline: none;
    }
  }
}

.primary_button {
  @apply h-24 text-4xl rounded-2xl text-white border-0 border-primary_blue bg-primary_blue;
}

.secondary_button {
  @apply h-24 text-4xl rounded-2xl text-white border-2 border-primary_blue;
}

.schedule_call_input {
  label {
    display: block;
    color: #fff;
    font-size: 2.4rem;
    font-weight: 400;
    padding-bottom: 1rem;
  }

  input {
    color: #fff;
    font-size: 2.25rem;
    height: 6rem;
    width: 100%;
    background-color: #202f39;
    border: solid 2px #fff;
    border-radius: 1rem;
    text-align: center;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #605f5f;
    }
  }

  textarea {
    color: #fff;
    font-size: 2.25rem;
    padding: 1.2rem 1.8rem;
    width: 100%;
    background-color: #202f39;
    border: solid 2px #fff;
    border-radius: 1rem;
    text-align: start;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #605f5f;
    }
  }
}

.schedule_call_input_date {
  .react-datepicker__month .react-datepicker__month-text{
    display: inline-block;
    width: 7rem;
    margin: 2px;
    height: 3rem;
    font-size: 17px;
  }
  .react-datepicker-year-header{
    font-size: 17px;
  }
  .react-datepicker__month-container{
    min-width: auto !important;
  }
 
  label {
    display: block;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
  }

  input {
    color: #fff;
    font-size: 2.25rem;
    height: 5.5rem;
    width: 80%;
    background-color: #202f39;
    border: solid 1px #fff;
    border-radius: 1rem;
    text-align: center;
    padding: 1rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #605f5f;
    }
  }

  textarea {
    color: #fff;
    font-size: 2rem;
    padding: 1.2rem 1.8rem;
    width: 100%;
    background-color: #202f39;
    border: solid 2px #fff;
    border-radius: 1rem;
    text-align: start;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #605f5f;
    }
  }
}

//REACT DATEPICKER OVERRIDE
.react-datepicker {
  width: 100%;
  font-family: "Roboto" !important;
  //border-radius: 1.3rem !important;

  &__month-container {
    min-width: 28rem;
    //border-radius: 1.3rem !important;
  }

  &__time-container {
    min-width: 17rem !important;
  }

  &__time-box {
    width: 100% !important;
  }

  &__time-list-item {
    font-size: 1.8rem;
    font-weight: 600;
    color: #616161 !important;

    &:hover {
      background-color: #e7e6e6 !important;
      color: #2165a2 !important;
    }

    &--selected {
      color: #fff !important;
      background-color: #2165a2 !important;
    }

    &--keyboard-selected {
      color: #fff !important;
      background-color: #2165a2 !important;
    }
  }

  &__header {
    // min-height: 100px;
    border-top-right-radius: 1.3rem !important;
    border-top-left-radius: 1.3rem !important;
  }

  &__navigation {
    top: 10px !important;
  }

  &__current-month {
    font-size: 2.4rem !important;
    color: #2165a2 !important;
  }

  &__day-name {
    font-size: 1.6rem;
    font-weight: 700;
    color: #2165a2 !important;
    width: 4.8rem !important;
    height: 4.8rem !important;
    padding: 1.5rem 1rem;
  }

  &__triangle {
    &::after {
      left: -40px !important;
    }

    &::before {
      left: -40px !important;
    }
  }

  &__day {
    font-size: 1.8rem;
    font-weight: 600;
    color: #616161 !important;
    width: 4.8rem !important;
    height: 4.8rem !important;
    padding: 1.5rem 1rem;

    &:hover {
      background-color: #e7e6e6 !important;
      color: #2165a2 !important;
    }

    &--selected {
      color: #fff !important;
      background-color: #2165a2 !important;
    }

    &--keyboard-selected {
      color: #fff !important;
      background-color: #2165a2 !important;
    }
  }
}

.react-datepicker-time {
  &__header {
    font-size: 2.4rem !important;
    color: #2165a2 !important;
  }
}

.error {
  color: red;
  font-size: 1.6rem;
  margin-top: 0.8rem;
  margin-left: 1rem;
}

.form_dot {
  position: relative;

  &:last-child {
    margin: 0;
  }

  &::before {
    content: "";
    width: 8px;
    min-width: 8px;
    height: 8px;
    background: #fff;
    position: absolute;
    bottom: -5px;
    left: 50%;
    z-index: 28;
    border-radius: 100%;
  }

  @media (min-width: 600px) {
    &::before {
      bottom: 0;
      top: 36%;
      left: -5px;
    }
  }

  &.active {
    &::before {
      background: #299aff;
    }
  }
}

.tabs_blk {
  &::-webkit-scrollbar {
    display: none;
  }
}

#swot_tabs {
  li {
    &::before {
      content: "\2022";
      color: #299aff;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}

//ADDING GRAY FILTER TO IMAGE
.grey_image_filter {
  filter: brightness(0) saturate(100%) invert(61%) sepia(0%) saturate(32%)
    hue-rotate(155deg) brightness(107%) contrast(98%);
}

.rbc-header {
  margin-top: 1rem !important;
  border-bottom: none !important;
  font-size: 2.4rem !important;
  color: #2165a2 !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}

.rbc-button-link {
  display: block !important;
  margin: 0 auto !important;
  margin-top: 1rem !important;
  text-align: center !important;
  color: #828282 !important;
  font-size: 2rem !important;
  font-weight: 500 !important;
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-current {
  .rbc-button-link {
    width: 3.8rem !important;
    min-width: 3.8rem !important;
    height: 3.8rem !important;
    overflow: hidden !important;
    background-color: #2165a2 !important;
    border-radius: 50% !important;
    color: white !important;
  }
}

.rbc-event {
  background-color: #2165a2;
  // width: 16.2rem !important;
  // height: 4.5rem !important;
  margin: 0 auto !important;
  margin-top: 0.6rem !important;
  padding-left: 4.5rem !important;
  position: relative;

  &::before {
    content: "";
    background-image: url("../icons/CallIcon.svg");
    background-repeat: no-repeat;
    background-size: 3rem 3rem;
    background-position: left center;
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
    z-index: 99;
    width: 3rem;
    height: 3rem;
  }
}

.rbc-event-content {
  text-align: center !important;
  font-size: 1.8rem !important;
  color: white;
  font-weight: 500 !important;
}

//apparel card Description

.description {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-datepicker-popper {
  z-index: 99;
}

// STREAK CALENDAR STYLES
.streak_calender {
  border: none !important;

  .react-datepicker__header {
    background-color: #fff !important;
    border-bottom: none !important;
  }

  .react-datepicker__month-container {
    min-width: 100%;
  }

  .react-datepicker__current-month {
    font-size: 1.8rem !important;
    color: #333333 !important;
    font-weight: 500 !important;
  }

  .react-datepicker__day-names {
    margin-top: 2rem;
  }

  .react-datepicker__day-name {
    font-size: 1.6rem !important;
    color: #333333 !important;
    font-weight: 500 !important;
    padding: 0.4rem 1rem !important;
    width: 4.6rem !important;
    height: 2.4rem !important;
  }

  .react-datepicker__day {
    // font-size: 1.6rem !important;
    width: 4.6rem !important;
    height: 3.4rem !important;
    padding: 0.4rem 1.1rem !important;
    cursor: default !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .react-datepicker__day--today {
    background-color: transparent !important;
    cursor: pointer;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
    cursor: pointer;
  }

  .react-datepicker__navigation:focus-visible {
    outline: none;
  }

  .react-datepicker__navigation--previous {
    left: 20px;
  }
  .react-datepicker__navigation--next {
    right: 20px;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #333333 !important;
  }

  .react-datepicker__day--outside-month {
    color: transparent !important;
    visibility: hidden !important;
    pointer-events: none;
  }
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  font-size: 1.8rem;
}

input.react-datepicker-time__input {
  font-size: 1.8rem;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px;
}

.rss_card_title {
  line-height: 2.2rem;
  height: 4.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.react-datepicker__day--in-range {
  color: #fff !important;
}

.event_des {
  line-height: 2.2rem;
  height: 6.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.full_name {
  min-height: 4.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.legacy_description {
  min-height: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.truncate_class {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* width */
#scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 1rem;
}

/* Handle */
#scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 1rem;
}

.zoom_week_days input[type="checkbox"]:checked + label {
  background-color: #2165a2;
  color: white;
}